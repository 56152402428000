import {useCallback, useMemo, useState} from 'react';
import {useQueryClient} from 'react-query';
import {QUERY_KEYS} from 'utils/const';

import {
  postLoginUser,
  postSignupUser,
  postLogout,
  postResetPassword,
  getUserData,
  notifyHubspotRegistration,
} from 'api/wizard';
import {LoginRequestParams, PostSignupParams} from 'pages/Wizard/types';
import {KeyStringItem} from 'store/pages/probationManagementStore';
import useTrackingState from 'context/Tracking/useTrackingState';
import useUiFeatures from '../pages/Wizard/hooks/useUiFeatures';
import {GTMEvents} from 'utils/globalEvents';
import Consts from 'utils/globalConstants';

const {FEATURE_WIZARD2_VERSION_ID} = Consts;

export type PostSignupUserProps = {
  status: number;
  data: {
    errors: [];
    user_id: string;
    user_ip: string;
  };
};

export type HandleLoginParams = {
  username: string;
  password: string;
  twoFactorAuthCode?: string;
  recaptchaValue?: string | null;
  captchaService?: string | null;
};

export type HandleSignUpParams = {
  username: string;
  email: string;
  recaptchaValue: string | null | undefined;
  password: string;
  agreeTerms: boolean;
  userType?: string | null | undefined;
  userCountry?: string | null | undefined;
  companyName?: string | null | undefined;
  config: any;
  registrationPage: any;
  captchaService?: any;
};

export type handlePostProjectNoteParams = {
  CSRFToken: string;
  id: string;
  subject: string;
  content: string;
};

export type HandleLogin = (params: HandleLoginParams, config: any) => void;
export type HandleSignUp = (params: HandleSignUpParams) => void;
export type HandleLogout = ({CSRFToken}: {CSRFToken: string}) => void;
export type handleResetPassword = (email: string, CSRFToken: string) => Promise<any>;

const useUserDataApi = () => {
  const {gtmTrack} = useTrackingState(null);
  const {uiFeatures} = useUiFeatures();
  const [errorMessages, setErrorMessages] = useState<{[key: string]: any}>({});
  const [createdUserId, setCreatedUserId] = useState<string | null>(null);
  const [responseStatus, setResponseStatus] = useState('idle');
  const needTwoFactorAuth = responseStatus === 'need two factor auth';

  const queryClient = useQueryClient();

  const isWizardEntry = useMemo(() => window.location.href.includes('/wizard/2/entry'), []);

  const handleRedirect = useCallback(
    (userType: string) => {
      const entryValue = sessionStorage.getItem('entryValue');
      if (isWizardEntry && entryValue === 'windowShopping' && userType === 'customer') {
        return window.location.replace('my-projects');
      }
    },
    [isWizardEntry]
  );

  const featureWizardEntry = useMemo(
    () => uiFeatures && uiFeatures[FEATURE_WIZARD2_VERSION_ID]?.config?.wizard2_version,
    [uiFeatures]
  );

  const handleErrorLogin = (err: any) => {
    setResponseStatus('idle');
    const message = err?.response?.data?.message;

    if (err?.response?.status === 403 && window?.resetFriendlyCaptcha) {
      window?.resetFriendlyCaptcha();
    }

    if (err?.response?.status === 417) {
      setResponseStatus('need two factor auth');
    }

    if (typeof message !== 'string') {
      return;
    }
    if (message.startsWith('[username]')) {
      setErrorMessages({
        full_name: [message.replace('[username]', '')],
      });
      gtmTrack({
        event: GTMEvents.login_fail,
        fail_reason: 'Invalid username',
      });
    } else if (message.startsWith('[password]')) {
      gtmTrack({
        event: GTMEvents.login_fail,
        fail_reason: 'Invalid password',
      });

      setErrorMessages({
        pwd: [message.replace('[password]', '')],
      });
    } else if (message.startsWith('Captcha')) {
      gtmTrack({
        event: GTMEvents.login_fail,
        fail_reason: 'Captcha error',
      });
      setErrorMessages({
        'g-recaptcha_err': [message],
      });
    } else {
      gtmTrack({
        event: GTMEvents.login_fail,
        fail_reason: 'Something went wrong',
      });
      setErrorMessages({
        common: [message] || ['Something went wrong'],
      });
    }
  };

  const handleErrorSignup = (errors: any) => {
    setResponseStatus('idle');
    if (errors && typeof errors === 'object') {
      let errResult = {...errors};
      if (errors['g-recaptcha_err']) {
        gtmTrack({
          event: GTMEvents.signup_fail,
          fail_reason: 'Recaptcha error',
        });
        errResult = {
          ...errors,
          'g-recaptcha_err': [errors['g-recaptcha_err']],
        };
      }
      gtmTrack({
        event: GTMEvents.signup_fail,
        fail_reason: Object.values(errResult).join(', '),
      });
      setErrorMessages(errResult);
      /**  let errResult = {
       pwd: ['Please enter your password'],
       full_name: ['Please provide full name'],
       Was 'g-recaptcha_err': 'Captcha Required' became 'g-recaptcha_err': ['Captcha Required']
       };
       */
    }

    if (errors && errors['g-recaptcha_err'] && window?.resetFriendlyCaptcha) {
      window?.resetFriendlyCaptcha();
    }
  };

  const updateUserData = () => {
    queryClient.invalidateQueries(QUERY_KEYS.USER_DATA);
  };

  const handleLogIn: HandleLogin = (
    {username, password, twoFactorAuthCode, recaptchaValue, captchaService},
    config
  ) => {
    const params: LoginRequestParams = {
      username,
      password,
      'captcha-service': captchaService || '',
    };

    setResponseStatus('loading');
    if (needTwoFactorAuth) {
      params['2fa_code'] = twoFactorAuthCode;
    }

    if (errorMessages?.['g-recaptcha_err']?.[0] || recaptchaValue) {
      params.captcha = recaptchaValue;
    }

    postLoginUser(params, config)
      .then((responseData: any) => {
        setResponseStatus('idle');
        if (responseData.status === 200) {
          getUserData()
            .then(({data}) => {
              const {id, type} = data?.results?.User || {};

              if (id && type) {
                handleRedirect(type.toLowerCase());

                const eventData = {
                  user_id: id,
                  is_translator: type === 'translator' ? 'yes' : 'no',
                };

                gtmTrack({event: GTMEvents.login_success, ...eventData});
              }
            })
            .catch((err) => console.log(err));

          updateUserData();
          setErrorMessages({});
        }
      })
      .catch(handleErrorLogin);
  };

  const handleSignUp: HandleSignUp = ({
    username,
    email,
    password,
    recaptchaValue,
    agreeTerms,
    userType,
    userCountry,
    companyName,
    config,
    registrationPage,
    captchaService,
  }) => {
    setResponseStatus('loading');
    const params: PostSignupParams = {
      full_name: username,
      user_email: email,
      pwd: password,
      action: 'reg',
      agree_terms: agreeTerms ? 'On' : '',
      user_type: userType || 'client',
      user_country: userCountry || '',
      company_name: companyName || '',
      'g-recaptcha-response': recaptchaValue,
      registrationPage,
      captcha_service: captchaService || '',
    };

    postSignupUser(params)
      .then(({status, data: {errors, user_id, user_ip}}: PostSignupUserProps) => {
        setResponseStatus('idle');
        if (status !== 200) {
          return;
        }

        if (errors) {
          handleErrorSignup(errors);
        } else if (user_id) {
          window.localStorage.setItem('newUser_noPayments', 'true');

          const userDataType = params.user_type === 'translator' ? 'translator' : 'client';

          gtmTrack({
            event: GTMEvents.registration,
            user_type: userDataType,
          });

          const eventData: KeyStringItem = {
            user_type: userDataType,
            user_id,
          };

          if (window.location.href.indexOf('wizard') > -1) {
            eventData.wizard_version = featureWizardEntry;
          }

          gtmTrack({
            event: GTMEvents.signup_success,
            user_id,
            is_translator: userType === 'translator' ? 'yes' : 'no',
            ...eventData,
          });

          const capitalizeFirstLetter = (name: string) => name.charAt(0).toUpperCase() + name.slice(1);

          const fullName = username.replace(/\s+/g, ' ').trim();
          const splitName = fullName.split(' ');

          const firstName = capitalizeFirstLetter(splitName[0]);
          let lastName = '';
          for (let i = 1; i < splitName.length; i++) {
            const space = i < splitName.length - 1 ? ' ' : '';
            lastName += capitalizeFirstLetter(splitName[i]) + space;
          }

          const hubspotData = {
            firstname: firstName,
            lastname: lastName,
            email,
            company: companyName || '',
            business_personal__c: userDataType,
            oht_user_id__c: user_id,
            ip_address__c: user_ip || '',
          };

          if (params.user_type !== 'translator') {
            notifyHubspotRegistration(config, hubspotData);
          }

          setCreatedUserId(user_id);
          handleLogIn({username: email.trim(), password: password}, config);
          if (params.user_type === 'translator') {
            window.location.replace('/profile/?flow=1#languages');
          }

          handleRedirect(params.user_type);
        }
      })
      .catch((err: any) => {
        setResponseStatus('idle');
        console.log('err', err);
      });
  };

  const handleLogout = ({CSRFToken}: {CSRFToken: string}) => {
    const formData = new FormData();
    formData.append('CSRFToken', CSRFToken);
    gtmTrack({event: GTMEvents.logout});
    postLogout(formData)
      .then((response) => {
        if (response?.status === 200) {
          setCreatedUserId(null);
          updateUserData();
        }
      })
      .catch((err) => console.log(err));
  };

  const handleResetPassword = async (email: string, CSRFToken: string) => {
    setResponseStatus('loading');

    const res = await postResetPassword(email, CSRFToken);
    setResponseStatus('idle');
    return res?.data;
  };

  const isFetchingAuth = responseStatus === 'loading';
  return {
    createdUserId,
    needTwoFactorAuth,
    // needCaptcha,
    errorMessages,
    setErrorMessages,
    handleLogIn,
    handleSignUp,
    handleLogout,
    handleResetPassword,
    isFetchingAuth,
    updateUserData,
  };
};

export default useUserDataApi;
