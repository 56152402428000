import React, {FC, ReactNode, useMemo} from 'react';
import {toast, ToastOptions} from 'react-toastify';
import styled from 'styled-components';
import BaseIcon from './BaseIcon';
import Info from 'assets/icons_refactor/Wizard/notify-info.svg';
import Warning from 'assets/icons_refactor/Wizard/notify-warning.svg';
import Error from 'assets/icons_refactor/Wizard/notify-failure.svg';
import Success from 'assets/icons_refactor/Wizard/notify-success.svg';

export type NotificationType = 'info' | 'success' | 'warning' | 'error';

interface Props {
  type: NotificationType;
  text: ReactNode;
}

const BaseNotification: FC<Props> = ({type, text}) => {
  const notificationParams = useMemo(() => {
    const target: any = {
      info: {
        icon: Info,
        fill: 'rgb(52, 152, 219)',
      },
      warning: {
        icon: Warning,
        fill: 'rgb(241, 196, 15)',
      },
      error: {
        icon: Error,
        fill: 'rgb(231, 76, 60)',
      },
      success: {
        icon: Success,
        fill: 'rgb(7, 188, 12)',
      },
    };
    return target[type];
  }, [type]);

  const {icon, fill} = notificationParams;

  return (
    <Content>
      <BaseIcon icon={icon} fill={`${fill}!important`} />
      {text}
    </Content>
  );
};

const Content = styled.div`
  display: grid;
  grid-template-columns: 25px auto;
  grid-column-gap: 10px;
  color: rgb(117, 117, 117);
`;

interface NotificationOptions extends Omit<ToastOptions, 'type'> {
  type?: NotificationType;
}

export const showNotification = (message: ReactNode, options: NotificationOptions = {}): void => {
  const {type, ...rest} = options;

  toast(<BaseNotification type={type ?? 'info'} text={message} />, {type, ...rest});
};
